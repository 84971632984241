import { createGlobalStyle } from 'styled-components'
import ldColor from '../utilities/lightenDarkenColor'

const GlobalStyles = createGlobalStyle`
  :root {
    --blue: #1C5CA6;
    --blue-hover: ${ldColor(`#1C5CA6`, -12)};
    --pink: #F5B9C4;
    --pink-hover: ${ldColor(`#F5B9C4`, -20)};
    --disabled-color: #999;
    --complete-color: #555;
    --text-color: #999;
    --blue-trans-5: rgba(28,92,166, 0.05);
    --blue-trans-15: rgba(28,92,166, 0.15);
    --base-font-size: 10px;
    --box-shadow: 0 0 20px var(--blue-trans-15);
    --gap-layout: 2rem;
    --gap-items: 2rem;
    --section-spacing: 8rem;
    --panel-border-radius: 6px;
    --input-border-radius: 4px;
    @media (max-width: 767px) {
      --base-font-size: 8px;
      --section-spacing: 4rem;
    }
  }
  html {
    font-size: var(--base-font-size);
    scroll-behavior: smooth;
  }

  body {
    font-size: 1.6rem;
    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  .button, button {
    display: inline-block;
    background: var(--blue);
    color: white;
    font-weight: bold;
    border: 0;
    padding: 2rem 2.4rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background: var(--blue-hover);
      text-decoration: none;
    }
    &:disabled, &[disabled] {
      background: var(--disabled-color);
      &.complete {
        background: var(--complete-color);
        cursor: default;
      }
    }
    &.pink {
      background: var(--pink);
      &:hover {
        background: var(--pink-hover);
      }
    }
  }

  /* Scrollbar Styles */ /*
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }*/

  hr {
    border: 0;
    margin: 0;
    height: 1px;
    background-color: var(--blue-trans-15);
  }

  img {
    max-width: 100%;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    ${'' /* image-rendering: pixelated; */}
  }

  .panel {
    background-color: white;
    padding: 3rem;
    box-shadow: var(--box-shadow);
    border-radius: var(--panel-border-radius);
  }
`

export default GlobalStyles
