import styled from 'styled-components'

const PageWidth = styled.div`
  margin: 0 auto;
  padding-left: var(--gap-layout);
  padding-right: var(--gap-layout);
  @media (min-width: 1199px) {
    width: 1000px;
  }
  @media (min-width: 1399px) {
    width: 1200px;
  }
  @media (min-width: 1599px) {
    width: 1400px;
  }
  /* @media (min-width: 1600px) {
    width: 1400px;
  } */
`

export default PageWidth
