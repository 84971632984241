import React from 'react'
import styled from 'styled-components'
import Logo from '../assets/images/inline/carebeds-logo.svg'
import PageWidth from '../styles/PageWidth'

const NavStyles = styled.nav`
  padding: 3rem 0;
  display: grid;
  grid-template-columns: 180px auto;
  align-items: center;
  .logo {
    width: 100%;
  }
  .links {
    margin: 0;
    padding: 0;
    list-style: none;
    justify-self: end;
    display: grid;
    grid-template-columns: repeat(4, max-content);
    gap: 2rem;
    a {
      padding: 1rem;
      font-weight: bold;
    }
  }
  @media (max-width: 991px) {
    justify-content: center;
    .links {
      display: none;
    }
  }
`

export default function Nav() {
  return (
    <PageWidth>
      <NavStyles>
        <Logo className="logo" />
        <ul className="links">
          <li>
            <a href="#what-we-do" title="Go to about us section">
              What We Do
            </a>
          </li>
          <li>
            <a href="#stories" title="Go to stories section">
              Stories
            </a>
          </li>
          <li>
            <a href="#get-involved" title="Go to get involved section">
              Get Involved
            </a>
          </li>
          <li>
            <a href="#get-involved" title="Go to contact section">
              Contact
            </a>
          </li>
        </ul>
      </NavStyles>
    </PageWidth>
  )
}
