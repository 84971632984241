import { createGlobalStyle } from 'styled-components'

import fontBookWoff from '../assets/fonts/Stolzl-Book.woff'
import fontBookTtf from '../assets/fonts/Stolzl-Book.ttf'
import fontBoldWoff from '../assets/fonts/Stolzl-Bold.woff'
import fontBoldTtf from '../assets/fonts/Stolzl-Bold.ttf'

const Typography = createGlobalStyle`
  @font-face {
    font-family: 'Stolzl';
    src:  url(${fontBookWoff}) format('woff'),
          url(${fontBookTtf}) format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Stolzl';
    src:  url(${fontBoldWoff}) format('woff'),
          url(${fontBoldTtf}) format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  html {
    font-family: Stolzl, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--text-color);
  }
  p {
    line-height: 1.6em;
  }
  h1 {
    font-size: 4.2rem;
  }
  h2 {
    font-size: 4.2rem;
  }
  h3 {
    font-size: 2rem;
    @media (max-width: 767px) {
      font-size: 2.4rem;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    color: var(--blue);
    font-weight: bold;
  }
  a {
    color: var(--blue);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .text-center {
    text-align: center;
  }
`

export default Typography
